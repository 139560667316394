<template>
  <div class="list-user">
    <div class="list-user-table background-content-admin">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          トリガー管理
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button v-on:click="createTrigger()" class="button-create-user">
            新規追加
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row pt-2">
        <div class="row-input col-sm-6 col-lg-4">
          <label class="w-100 font-weight-bold pl-0">トリガー名</label>
          <input class="form-control w-100" v-model="slagName" />
        </div>
      </div>
      <div class="row pt-2">
        <div class="row-input col-sm-6 col-lg-4">
          <div class="w-100">
            <label class="w-100 font-weight-bold pl-0">条件設定</label>
          </div>
          <div class="row-input">
            <b-form-select
              class="w-100"
              id="select-cate"
              v-model="type"
              :options="listTriggerSetting"
            ></b-form-select>
          </div>
        </div>
      </div>

      <!-- <div class="row p-3 d-flex">
        <label class="col-12 font-weight-bold pl-3">ステータス </label>
        <div class="col-3 row-input p-3 status-select">
          <b-form-select
            class="col-12"
            id="select-cate"
            v-model="setting_link"
            :options="settingLinkOptions"
          ></b-form-select>
        </div>
      </div> -->
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListTrigger(1)"
          variant="info"
          class="mr-2"
          :disabled="isLoading"
          >絞り込み検索</b-button
        >
        <b-button v-on:click="clearInputSearch()"> リセット </b-button>
      </div>
      <Tables
        :items="ListTrigger.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:id="{ index }">
          <td>
            {{
              ListTrigger.total - index - (ListTrigger.current_page - 1) * limit
            }}
          </td>
        </template>
        <template v-slot:trigger_name="{ item }">
          <td class="text-nowrap">
            {{ item.trigger_name }}
          </td>
        </template>
        <template v-slot:trigger_setting="{ item }">
          <td class="text-nowrap">
            {{ item.trigger_setting }}
          </td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            <div>
              {{ getDate(item.created_at) }}
            </div>
            <div>
              {{ getHour(item.created_at) }}
            </div>
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <CButton
              class="mx-1 text-nowrap"
              color="primary"
              square
              v-on:click="editTrigger(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.modal-comfirm
            >
              編集
            </CButton>
            <CButton
              class="mx-1 text-nowrap"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-slag
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="ListTrigger.last_page"
      >
        <p class="font-weight-bold">
          {{ ListTrigger.total }}件中{{ ListTrigger.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="ListTrigger.last_page"
      >
        <CPagination
          :activePage.sync="page"
          :pages="ListTrigger.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-slag" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            :disabled="isDisable"
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="blockTrigger(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-slag')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { Constants } from "../../utils/constants";

export default {
  name: "listTrigger",
  data() {
    return {
      type: ["type1", "type2", "type3"],
      fields: tableFields.TRIGGER,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      listTriggerSetting: [
        { text: "条件設定1", value: 0 },
        { text: "条件設定2", value: 1 },
        { text: "条件設定3", value: 2 },
        { text: "条件設定4", value: 3 },
        { text: "条件設定5", value: 4 },
        { text: "条件設定6", value: 5 },
        { text: "条件設定7", value: 6 },
        { text: "条件設定8", value: 7 },
      ],
      dataModal: "",
      slagName: "",
      email: "",
      dateCreateFrom: "",
      dateCreateTo: "",
      dateUpdatedFrom: "",
      dateUpdatedTo: "",
      limit: 50,
      page: 1,
      // setting_link: null,
      // settingLinkOptions: [
      //   { text: "なし", value: 0 },
      //   { text: "あり", value: 1 },
      // ],
      isDisable: false,
      isLoading: false,
      ListTrigger: {
        current_page: "1",
        last_page: 1,
        total: "10",
        data: [
          {
            id: "10",
            trigger_name: "トリガー10",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定10",
          },
          {
            id: "9",
            trigger_name: "トリガー9",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定9",
          },
          {
            id: "8",
            trigger_name: "トリガー8",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定8",
          },
          {
            id: "7",
            trigger_name: "トリガー7",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定7",
          },
          {
            id: "6",
            trigger_name: "トリガー6",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定6",
          },
          {
            id: "5",
            trigger_name: "トリガー5",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定5",
          },
          {
            id: "4",
            trigger_name: "トリガー4",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定4",
          },
          {
            id: "3",
            trigger_name: "トリガー3",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定3",
          },
          {
            id: "2",
            trigger_name: "トリガー2",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定2",
          },
          {
            id: "1",
            trigger_name: "トリガー1",
            created_at: "2022-03-30 00:00:00",
            trigger_setting: "条件設定1",
          },
        ],
      },
    };
  },
  components: {
    Tables,
  },
  created() {
    // this.getListSlags(1);
  },
  computed: {
    ...mapGetters(["listSlag", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },
    listSlag() {
      //   this.page = this.listSlag.current_page;
      //   this.isLoading = false;
    },
    page() {
      //   this.getListSlags(this.page);
    },
  },
  methods: {
    ...mapActions({ getListSlag: "getListSlag" }),
    ...mapActions({ deleteSlag: "deleteSlag" }),
    getDate(date) {
      return moment(new Date(date)).format("YYYY/MM/DD");
    },
    getHour(date) {
      return moment(new Date(date)).format("hh:mm");
    },
    createTrigger() {
      localStorage.setItem(
        Constants.NO_TRIGGER,
        parseInt(this.ListTrigger.total) + 1
      );
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Trigger"
          : "Create Trigger domain",
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    editTrigger(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Trigger"
          : "Edit Trigger domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      //   this.getListSlags(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    clearInputSearch() {
      this.slagName = "";
      this.dateCreateFrom = "";
      this.dateCreateTo = "";
      this.dateUpdatedFrom = "";
      this.dateUpdatedTo = "";
      // this.setting_link = "";
    },
    blockTrigger(dataModal) {
      var obj = [];
      this.ListTrigger.data.forEach((item) => {
        if (item.id != dataModal) {
          obj.push(item);
        }
      });
      this.ListTrigger.data = obj;
      this.$bvModal.hide("delete-modal-slag");
      this.isDisable = false;
    },
    getListTrigger(page) {
      console.log(page);
      //   if (page === undefined) {
      //     page = this.page;
      //   }
      //   this.isLoading = true;
      //   const formData = {
      //     page: page,
      //     data: {
      //       shop_id: this.$route.params.shopId,
      //       slag_name_en: this.slagName,
      //       created_from: this.dateCreateFrom,
      //       created_to: this.dateCreateTo,
      //       updated_from: this.dateUpdatedFrom,
      //       updated_to: this.dateUpdatedTo,
      //       // setting_link: this.setting_link,
      //       limit: this.limit,
      //     },
      //   };
      //   this.getListTrigger(formData);
    },
  },
};
</script>
